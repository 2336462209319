import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { FetchData } from "./components/FetchData";
import Groceries from "./components/Groceries";
import FinancesUploadInvestments from "./components/FinancesUploadInvestments";
import FinancesUploadStatements from "./components/FinancesUploadStatements";
import FinancesSummary from "./components/FinancesSummary";
import FinancesByAccount from "./components/FinancesByAccount";
import FinancesSales from "./components/FinancesSales";
import FinancesWithdrawals from "./components/FinancesWithdrawals";
import FinancesStaking from "./components/FinancesStaking";
import FinancesTagged from "./components/FinancesTagged";
import FinancesMatchWithdrawals from "./components/FinancesMatchWithdrawals";
import FinancesMatchDeposits from "./components/FinancesMatchDeposits";
import FinancesSettings from "./components/FinancesSettings";
import DumpList from './components/DumpList';
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/fetch-data',
    requireAuth: true,
    element: <FetchData />
  },
  {
    path: '/groceries',
    requireAuth: true,
    element: <Groceries />
  },
  {
    path: '/finances/uploadinvestments',
    requireAuth: true,
    element: <FinancesUploadInvestments />
  },
  {
    path: '/finances/uploadstatements',
    requireAuth: true,
    element: <FinancesUploadStatements />
  },
  {
    path: '/finances/summary',
    requireAuth: true,
    element: <FinancesSummary />
  },
  {
    path: '/finances/accounts',
    requireAuth: true,
    element: <FinancesByAccount />
  },
  {
    path: '/finances/sales',
    requireAuth: true,
    element: <FinancesSales />
  },
  {
    path: '/finances/withdrawals',
    requireAuth: true,
    element: <FinancesWithdrawals />
  },
  {
    path: '/finances/staking',
    requireAuth: true,
    element: <FinancesStaking />
  },
  {
    path: '/finances/tagged',
    requireAuth: true,
    element: <FinancesTagged />
  },
  {
    path: '/finances/matchwithdrawals',
    requireAuth: true,
    element: <FinancesMatchWithdrawals />
  },
  {
    path: '/finances/matchdeposits',
    requireAuth: true,
    element: <FinancesMatchDeposits />
  },
  {
    path: '/finances/settings',
    requireAuth: true,
    element: <FinancesSettings />
  },
  {
    path: '/todo/dump',
    requireAuth: true,
    element: <DumpList />
  },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
