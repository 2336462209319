import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService';
import Table from './controls/Table';

function FinancesWithdrawals() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getwithdrawals', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const withdrawalLotColumns = React.useMemo(
    () => [
      {
        Header: 'Note',
        accessor: 'note',
        Footer: '',
      },
      {
        Header: 'Id',
        accessor: 'id',
        Footer: '',
      },
      {
        Header: 'Account',
        accessor: 'account.friendlyName',
        Footer: '',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Footer: '',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Footer: '',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Footer: '',
      },
      {
        Header: 'Original Quantity',
        accessor: 'originalQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.originalQuantity + sum, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
      {
        Header: 'Current Quantity',
        accessor: 'currentQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentQuantity + sum, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
      {
        Header: 'Original Basis',
        accessor: 'originalBasis',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.originalBasis + sum, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
      {
        Header: 'Current Basis',
        accessor: 'currentBasis',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentBasis + sum, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.currentBasis / row.original.currentQuantity);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        Footer: '',
      },
      {
        Header: 'FeesUSD',
        accessor: 'feesUSD',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.feesUSD || 0);
          }, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
      {
        Header: 'Crypto Fee',
        accessor: 'cryptoFee',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.cryptoFee || 0);
          }, 0);
          return <>{total.toFixed(8)}</>;
        },
      },
    ],
    []
  );

  return (
    <div>
      <div>
        <h2>Withdrawals ({data?.length})</h2>
        <br></br>
        <div>
          {data?.map(withdrawalDetails => {
            return (
              <div>
                <h4>{withdrawalDetails.withdrawal.symbol} ({withdrawalDetails.withdrawal.currentQuantity})</h4>
                <Table
                  columns={withdrawalLotColumns}
                  data={[
                    ...withdrawalDetails.lots,
                    withdrawalDetails.withdrawal,
                  ]}>
                </Table>
                {withdrawalDetails.deposits.length > 0 && (
                  <Table
                    columns={withdrawalLotColumns}
                    data={[
                      withdrawalDetails.withdrawal,
                      ...withdrawalDetails.deposits,
                    ]}>
                  </Table>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FinancesWithdrawals;