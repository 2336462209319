import React, { useEffect, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';

function FinancesMatchWithdrawals() {
  const [data, setData] = useState([]);
  const [quantitySelected, setQuantitySelected] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      clearCheckBoxes();
      setQuantitySelected(0);
      disableAllButtons();
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getwithdrawalsunmatched', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
      enableAllButtons();
    } catch (error) {
      console.error(error);
    }
  };

  const disableAllButtons = () => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => { button.disabled = true; });
  };

  const enableAllButtons = () => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => { button.disabled = false; });
  };

  const clearCheckBoxes = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => { checkbox.checked = false; });
  };

  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  function handleCheckboxQuantityChange(event, quantity) {
    const isChecked = event.target.checked;
    if (isChecked)
      setQuantitySelected(quantitySelected + quantity);
    else
      setQuantitySelected(quantitySelected - quantity);
  }

  const handleActionOnTransactions = async (action) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');

    const transactionIds = [];
    checkboxes.forEach((checkbox) => { transactionIds.push(checkbox.id); });

    const token = await authService.getAccessToken();

    fetch(action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
      body: JSON.stringify(transactionIds),
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            return response.json().then(errorData => {
              throw new Error(JSON.stringify(errorData.errors));
            });
          } else {
            throw new Error('Failed: ' + response.statusText);
          }
        }
        getData();
      })
      .catch(error => {
        console.error('Error with matchtransactions:', error);
        //setMessage('Error uploading file: ' + error.message);
      });

    await getData();

  }

  const handleMatchTransactionsButtonClick = async () => {
    await handleActionOnTransactions('api/finances/matchtransactions');
  }

  const handleIgnoreTransactionsButtonClick = async () => {
    await handleActionOnTransactions('api/finances/ignoretransactions');
  }

  const handleRevertToOriginButtonClick = async () => {
    await handleActionOnTransactions('api/finances/reverttoorigin');
  }

  return (
    <div>
      <div>
        <h2>Match Withdrawals and Sales (
          {data?.reduce((total, symbolGroup) => total + symbolGroup.assets.filter(t => t.type === "WITHDRAWAL").length, 0)}W,
          {data?.reduce((total, symbolGroup) => total + symbolGroup.assets.filter(t => t.type === "SELL").length, 0)}S) ({data?.length})</h2>
        <br></br>
        <br></br>
        {data?.map((symbolGroup, index) => {
          const unmatchedWithdrawals = symbolGroup.assets.filter(t => t.type === "WITHDRAWAL").length;
          const totalUnmatchedSale = symbolGroup.assets.filter(t => t.type === "SELL").length;
          return (
            <Accordion open={open} toggle={toggle} stayopen>
              <AccordionItem targetId={symbolGroup.symbol + index.toString()}>
                <AccordionHeader targetId={symbolGroup.symbol + index.toString()}>
                  <h5>{symbolGroup.accountName} <b>[{symbolGroup.symbol}]</b> ({unmatchedWithdrawals}W, {totalUnmatchedSale}S)</h5>
                </AccordionHeader>
                <AccordionBody accordionId={symbolGroup.symbol + index.toString()}>
                  <h5>Quantity Selected: {quantitySelected}</h5>
                  <button onClick={handleIgnoreTransactionsButtonClick} style={{margin:5}}>Ignore</button>
                  <button onClick={handleRevertToOriginButtonClick}>Revert to Origin</button>
                  <table className="table" style={{ flex: 1 }}>
                    <thead className="thead-dark">
                      <tr>
                        <th>Id</th>
                        <th>Date</th>
                        <th>Account</th>
                        <th>Type</th>
                        <th>Symbol</th>
                        <th>Quantity</th>
                        <th>PriceUSD</th>
                        <th>CryptoFee</th>
                        <th>Selected</th>
                        <th>Match</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {symbolGroup?.assets?.map((transaction, index2) => {
                          const unmatchedWithdrawal = transaction.type === "WITHDRAWAL";
                          const unmatchedSale = transaction.type === "SELL";
                        const needsMatching = unmatchedSale || unmatchedWithdrawal;
                        return (
                            <>
                              <tr key={transaction.id} style={{
                                backgroundColor: (unmatchedWithdrawal ? 'lightgrey' : (unmatchedSale ? 'lightyellow' : 'inherit')),
                                fontWeight: needsMatching ? 'bold' : 'normal'
                              }}>
                                <td>{transaction.id}</td>
                                <td>
                                  {new Date(transaction.date).toLocaleDateString('en-US', { dateStyle: 'short' })}
                                  <br />
                                  {new Date(transaction.date).toLocaleTimeString('en-US', { timeStyle: 'short' })}
                                </td>
                                <td><div title={transaction.account.id.toString()}>{transaction.account.friendlyName}</div></td>
                                <td>{transaction.type + '-' + transaction.subtype}</td>
                                <td>{transaction.symbol}</td>
                                <td><input type="checkbox"
                                  id={transaction.id}
                                  onChange={(event) => handleCheckboxQuantityChange(event, transaction.quantity)}
                                  style={{ marginRight: 10 }} />{transaction.quantity}
                                  <br></br>
                                  {transaction.fullQuantity !== transaction.quantity && "(" + transaction.fullQuantity + ")"}
                                </td>
                                <td>{
                                  transaction.quantity !== 0 ?
                                    Math.abs(transaction.costBasis / transaction.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) :
                                    Math.abs(transaction.costBasis / transaction.fullQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                                }</td>
                                <td>{transaction.cryptoFee}</td>
                                <td>{transaction.costBasis}<br></br>{transaction.fullCostBasis !== transaction.costBasis && "(" + transaction.fullCostBasis + ")"}</td>
                                <td>
                                  <div>
                                    <p>{quantitySelected}</p>
                                  </div>
                                </td>
                                <td>
                                  {needsMatching && <button onClick={handleMatchTransactionsButtonClick}>Match</button>}
                                </td>
                                <td>
                                  <input type="text" id="fname" name="firstname" placeholder=""></input>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default FinancesMatchWithdrawals;